import React from 'react'

export default function Attachments() {
    return (
        <div style={{width: '1080px', margin: '10px auto'}}>
            <h1>Payment details</h1>
            <p> BANK: PEKAO SA</p>
            <p>SWIFT CODE: PKOPPLPW</p>
            <p>IBAN PL: 90 1240 4722 1111 0000 4854 4715</p>

            <h3>Declarations</h3>
            <p></p>
            <p>Below you can find links for the declarations examples. </p>
            <p>Send them to our e-mail address with your abstracts: <b>genomica.uj@gmail.com</b></p>
           

            <a href="https://drive.google.com/file/d/1l2M2ijuATs29zk0ppKyU-NyqmcLdRd3z/view?usp=sharing"> Declaration template [PL]</a>
            <p></p>
            <a href="https://drive.google.com/file/d/1vTzGFE_JzTVs3cQcaV_1UadgdGm6y-bK/view?usp=sharing"> Declaration template [EN]</a>

            <h3>Abstract template</h3>
            <a href="https://drive.google.com/file/d/1lRZEhzLxxXjSO9998yAdFRQax8GanX6P/view?usp=sharing>" >Abstract template [PL]</a>
            <p></p>
            <a href="https://drive.google.com/file/d/19uqL3oknAzRIDj-KYoIu3Jw6X19baFJn/view?usp=sharing">Abstract template [EN]</a>
        </div>
    )
}