import React from 'react'
import ProgramList from '../../Components/Program/Program'
import './Program.scss'
import program from '../../Assets/program/schedule.jpg'

export default function Program() {

  return (
    <div className='Program-Page'>
      <ProgramList />
    </div>
    
  )
}
